<template>
  <b-card>
    <b-row align-h="end">
      <b-col cols="auto">
        <download-em-excel
          :filtro="filtroAtual"
          tipo="Situação fiscal"
          :api="apiSituacaoFiscal"
        />
      </b-col>
      <b-col cols="auto">
        <download-em-lote
          tipo-arquivo="situações"
          tipo="Situação Fiscal"
          :filtro="filtroAtual.filtroBy"
          :palavras-filtro="[['Regulares', 'Irregulares', 'Motivo']]"
          @baixar-lote="baixarEmLote"
        />
      </b-col>
    </b-row>
    <situacao-fiscal-list
      ref="situacaFiscalList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="abrirClick(props)"
        >
          <span
            v-if="props.row.statusAlterado === true"
            style="font-weight: bold"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <span
          v-if="props.column.field === 'consultadoEm' || props.column.field === 'cadastroFederal'"
        >
          <span
            v-if="props.row.statusAlterado === true"
            style="font-weight: bold"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <b-badge
          v-if="props.column.field === 'situacao' && props.row[props.column.field] == 'Regular'"
          variant="light-success"
          class="badge-size-p"
        >
          Regular
        </b-badge>
        <b-badge
          v-else-if="props.column.field === 'situacao' && props.row[props.column.field] == 'Irregular'"
          variant="light-danger"
          class="badge-size-p"
        >
          Irregular
        </b-badge>
        <b-badge
          v-else-if="props.column.field === 'situacao' && props.row[props.column.field] == 'Instabilidade'"
          v-b-tooltip.hover
          :title="getTooltipStatus(props.row.mensagemErro)"
          variant="light-primary"
          class="badge-size-p"
        >
          Instabilidade
        </b-badge>
        <b-badge
          v-else-if="props.column.field === 'situacao'"
          v-b-tooltip.hover
          :title="getTooltipSituacao(props.row[props.column.field])"
          variant="light-warning"
          class="badge-size-p"
        >
          {{ props.row[props.column.field] }}
        </b-badge>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          style="display: flex;"
          :filtros-rapidos="filtroRapidoLimpor"
          @atualizar-grid="atualizarGrid()"
        />
      </template>
    </situacao-fiscal-list>
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px !important;
}
.acoes-grid {
  display: flex !important;
}
</style>

<script>

import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'CndFederalVisualizacao',
  components: {
    SituacaoFiscalList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiSituacaoFiscal: api,
      botoesOption: null,
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'col-td-acao-2',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'ConsultadoEm'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '2',
        },
        {
          id: 'SituacaoComAlteracao',
          colunas: ['SituacaoComAlteracao'],
          label: 'Situação com alteração',
          valor: true,
        },
        {
          id: 'Procuracao',
          colunas: ['Situacao'],
          label: 'Procuração',
          valor: '4,9,10,11',
        },
        {
          id: 'ProcuracaoVencida',
          colunas: ['Situacao'],
          label: 'Procuração vencida',
          valor: '9',
        },
        {
          id: 'ProcuracaoCancelada',
          colunas: ['Situacao'],
          label: 'Procuração cancelada',
          valor: '10',
        },
        {
          id: 'SemProcuracao',
          colunas: ['Situacao'],
          label: 'Sem procuração',
          valor: '11',
        },
        {
          id: 'ProcuracaoLimitada',
          colunas: ['Situacao'],
          label: 'Procuração Limitada',
          valor: '6',
        },
        {
          id: 'VerificacaoDuasEtapas',
          colunas: ['Situacao'],
          label: 'Verificação duas etapas',
          valor: '15',
        },
        {
          id: 'BaseIndisponivel',
          colunas: ['Situacao'],
          label: 'Base indisponível',
          valor: '13',
        },
        {
          id: 'CadastroGov',
          colunas: ['Situacao'],
          label: 'Cadastro Gov',
          valor: '5',
        },
        {
          id: 'AutorizacaoEcac',
          colunas: ['Situacao'],
          label: 'Autorização Ecac',
          valor: '7',
        },
        {
          id: 'Instabilidade',
          colunas: ['Situacao'],
          label: 'Instabilidade',
          valor: '3',
        },
        {
          id: 'CertificadoRevogado',
          colunas: ['Situacao'],
          label: 'Certificado revogado',
          valor: '8',
        },
        {
          id: 'CadastroCPFSocio',
          colunas: ['Situacao'],
          label: 'Cadastro CPF sócio',
          valor: '12',
        },
        {
          id: 'PendenteDeAprovacao',
          colunas: ['Situacao'],
          label: 'Pendente de aprovação',
          valor: '14',
        },
        {
          id: 'SemSituacao',
          colunas: ['Situacao'],
          label: 'Sem situação fiscal',
          valor: '3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
        },
        {
          id: 'ProblemaNaAutenticacao',
          colunas: ['Situacao'],
          label: 'Problema na autenticação',
          valor: '16',
        },
        {
          id: 'OmissaoEFD',
          colunas: ['Omissao'],
          label: 'Omissão de EFD',
          valor: '1',
        },
        {
          id: 'OmissaoPGDAS',
          colunas: ['Omissao'],
          label: 'Omissão de PGDAS-D',
          valor: '2',
        },
        {
          id: 'OmissaoDIRF',
          colunas: ['Omissao'],
          label: 'Omissão de DIRF',
          valor: '3',
        },
        {
          id: 'OmissaoDCTF',
          colunas: ['Omissao'],
          label: 'Omissão de DCTF',
          valor: '4',
        },
        {
          id: 'OmissaoECF',
          colunas: ['Omissao'],
          label: 'Omissão de ECF',
          valor: '5',
        },
        {
          id: 'OmissaoGFIP',
          colunas: ['Omissao'],
          label: 'Omissão de GFIP',
          valor: '6',
        },
        {
          id: 'OmissaoDASN',
          colunas: ['Omissao'],
          label: 'Omissão de DASN',
          valor: '7',
        },
        {
          id: 'OmissaoDASNSIMEI',
          colunas: ['Omissao'],
          label: 'Omissão de DASN SIMEI',
          valor: '9',
        },
        {
          id: 'OmissaoDEFIS',
          colunas: ['Omissao'],
          label: 'Omissão de DEFIS',
          valor: '8',
        },
        {
          id: 'OmissaoDITR',
          colunas: ['Omissao'],
          label: 'Omissão de DITR',
          valor: '10',
        },
        {
          id: 'OmissaoDCTFWeb',
          colunas: ['Omissao'],
          label: 'Omissão de DCTFWeb',
          valor: '11',
        },
        {
          id: 'PendenciaGFIPxGPS',
          colunas: ['Pendencia'],
          label: 'Divergência GFIP x GPS',
          valor: '1',
        },
        {
          id: 'PendenciaDebitoSief',
          colunas: ['Pendencia'],
          label: 'Débito (Sief)',
          valor: '4',
        },
        {
          id: 'PendenciaDebitoSicob',
          colunas: ['Pendencia'],
          label: 'Débito (Sicob)',
          valor: '6',
        },
        {
          id: 'PendenciaDividasAtivas',
          colunas: ['Pendencia'],
          label: 'Dívidas ativas',
          valor: '3',
        },
        {
          id: 'ProcessoArrolamentoBensSief',
          colunas: ['Pendencia'],
          label: 'Processo de Arrolamento de Bens (Sief)',
          valor: '9',
        },
        {
          id: 'ProcessoFiscalSief',
          colunas: ['Pendencia'],
          label: 'Processo Fiscal (Sief)',
          valor: '8',
        },
        {
          id: 'ParcelamentoParcsnParcmei',
          colunas: ['Pendencia'],
          label: 'Parcelamento (PARCSN/PARCMEI)',
          valor: '5',
        },
        {
          id: 'ParcelamentoSipade',
          colunas: ['Pendencia'],
          label: 'Parcelamento (Sipade)',
          valor: '7',
        },
        {
          id: 'ParcelamentoSiefpar',
          colunas: ['Pendencia'],
          label: 'Parcelamento (Siefpar)',
          valor: '10',
        },
        {
          id: 'IrregularidadeCadastral',
          colunas: ['Pendencia'],
          label: 'Irregularidade Cadastral',
          valor: 11,
        },
      ],
      filtroRapidoLimpor: [],
      filtroAtual: {},
    }
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
    this.filtroLimpo()
    this.getTotalFiltro()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtros) {
      this.filtroAtual = filtros
      return api.getPaginado(pagina, tamanhoPagina, filtros)
    },
    abrirClick(props) {
      if (props.row.situacao === 'Irregular') {
        this.$refs[`acoes${props.row.id}`].abrirPendencias(props.row)
      } else if (props.row.situacao === 'Regular') {
        this.$refs[`acoes${props.row.id}`].abrirArquivo(props.row)
      } else if (props.row.situacao === 'SemSituacao') {
        this.$refs[`acoes${props.row.id}`].abrirArquivo(props.row)
      } else if (props.row.situacao === 'Base indisponível') {
        this.$refs[`acoes${props.row.id}`].abrirArquivo(props.row)
      }
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.situacaFiscalList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.situacaFiscalList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },
    getTooltipSituacao(situacao) {
      if (situacao === 'Cadastro Gov') {
        return 'Você precisa criar sua conta no portal gov.br. Conclua seu cadastro no Gov para garantir que todas as rotinas sejam executadas corretamente.'
      }
      if (situacao === 'Autorização e-CAC') {
        return 'Será necessário dar autorização de uso de dados. Faço o acesso ao portal e-CAC. Conclua a autorização para garantir que todas as rotinas sejam executadas corretamente.'
      }
      return ''
    },
    getTooltipStatus(mensagemErro) {
      return mensagemErro === undefined || mensagemErro === '' ? '' : `Erro na requisição: (${mensagemErro}), Por favor, Entre em contado com o suporte`
    },
    atualizarGrid() {
      this.$refs.situacaFiscalList.buscar()
      this.getTotalFiltro()
    },
    getTotalFiltro() {
      api.getTotalFiltroSituacaoFiscal(this.filtrosRapidos)
        .then(payload => {
          this.filtrosRapidos.forEach((element, i) => {
            this.filtroRapidoAtual = this.filtrosRapidos[i].label.split(' -')
            this.filtrosRapidos[i].label = `${this.filtroRapidoAtual[0]} - ${payload.data.find(s => s.nome === element.id).total}`
          })
        })
    },
    filtroLimpo() {
      this.filtrosRapidos.forEach((element, i) => {
        this.filtroRapidoAtual = this.filtrosRapidos[i].label.split(' -')
        this.filtroRapidoLimpor.push({
          id: this.filtrosRapidos[i].id,
          colunas: this.filtrosRapidos[i].colunas,
          label: this.filtroRapidoAtual[0],
          valor: this.filtrosRapidos[i].valor,
        })
      })
    },
  },
}
</script>
