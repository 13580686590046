export default [{
  label: 'Razão social',
  field: 'razaoSocial',
  type: 'custom',
},
{
  label: 'CNPJ/CPF',
  field: 'cadastroFederal',
  type: 'custom',
  tdClass: 'col-tamanho-cnpj',
},
{ label: 'Situação', field: 'situacao', type: 'custom' }, // reguar ou irregular
{ label: 'Consultado em', field: 'consultadoEm', type: 'custom' }]
